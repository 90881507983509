import React from "react";
import logoLeukidemia from "../../assets/logo-nav.svg";
import leukinew from "../../assets/leukinew.png";
import { useNavigate } from "react-router-dom";
import { AUTH_TOKEN, ID, USER } from "../../constants";
import logoVr from "../../assets/logoVr.svg";
import "./navbar.scss";
import { useEffect } from "react";

export default function Navbar() {
  const navigate = useNavigate();
  const token = localStorage.getItem(AUTH_TOKEN);
  const id = localStorage.getItem(ID);
  const user = localStorage.getItem(USER);
  const isLoggedIn = token && id && user;

  const handleNavigation = (path) => {
    if (window.location.pathname !== path) {
      navigate(path);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  useEffect(() => {
  }, [isLoggedIn])

  return (
    <div className="navbar-leukidemia">
      <div className="logo-leukidemia" onClick={() => handleNavigation("/")}>
        <img src={leukinew} alt="Leukidemia logo" />
      </div>
      <div className="button-navbar text-nav">
        {isLoggedIn ? (
          <>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleLogout()}
            >
              Deconnexion
            </div>
          </>
        ) : (
          <>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleNavigation("/login")}
            >
              Connexion
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleNavigation("/register")}
            >
              Inscription
            </div>
          </>
        )}
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleNavigation("/vr-guide")}
        >
          Guide Vr
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleNavigation("/datapage")}
        >
          Ressources Leucémie
        </div>
        <div
          style={{ cursor: "pointer" }}

          onClick={() => handleNavigation("/profile")}
        >
          <img className="round" src={logoVr} alt="Leukidemia logo" />
        </div>
      </div>
    </div>
  );
}
