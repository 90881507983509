import React from 'react'
import LoginForm from '../../components/form/LoginForm/LoginFrom'
import "./Login.scss";
import Navbar from '../../components/navbar/navbar.js';
  
function Login() {
  return (
    <div className='login-page'>
      <Navbar />
      <LoginForm />
    </div>
  )
}

export default Login