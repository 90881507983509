import "./Sidebar.scss"
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import pp from '../../assets/luffy.png'

export default function Sidebar() {
    let navigate = useNavigate();
    return (
        <nav className="sidebar">
            <div className="sidebar--header">
                <div className="sidebar--header--title">
                </div>
            </div>
            <div className="sidebar--content">
                <div className="page">
                    <div className={`button-nav button-nav--${
                        window.location.pathname === "/" ? "selectionned" : "inactive"
                    }`} onClick={(e) => {
                        e.preventDefault();
                        if (window.location.pathname !== "/") {
                            navigate('/');
                        }
                    }}> 
                        <span>Home</span>
                    </div>
                    <div className="button-nav" onClick={(e) => {
                        e.preventDefault();
                        if (window.location.pathname !== "/forum") {
                            navigate('/forum');
                        }
                    }}>
                        <span>Forum</span>
                    </div>

                    <div className={`button-nav button-nav--${
                        window.location.pathname === "/vr-guide" ? "selectionned" : "inactive"
                    }`} onClick={(e) => {
                        e.preventDefault();
                        if (window.location.pathname !== "/vr-guide") {
                            navigate('/vr-guide');
                        }
                    }}>
                        <span>Guide VR</span>
                    </div>
                </div>
                <div className="useur">
                    <div className="button-nav" onClick={(e) => {
                        e.preventDefault();
                        if (window.location.pathname !== "/profile") {
                            navigate('/profile');
                        }
                    }}>
                        <span>Profile</span>
                    </div >

                    <div className="button-nav" onClick={(e) => {
                        e.preventDefault();
                        if (window.location.pathname !== "/login") {
                            navigate('/login');
                        }
                    }}>
                        <span>Login</span>
                    </div>
                    <div className="button-nav button-nav--disabled">
                        <span>Logout</span>
                    </div>
                </div>
                <div className="profile-useur">
                        <span>
                            <img style={{"border-radius": "30px"}} src={pp} alt="pp" width={30} />
                        </span>
                        <p> Charlie </p>
                </div>
            </div>
        </nav >

    );
}