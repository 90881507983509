import React, { useEffect } from 'react'
import "./Home.scss";
import "../../components/Sidebar/Sidebar.js"
import Navbar from '../../components/navbar/navbar.js';
import homeLogo from "../../assets/home.svg";
import exclamation from "../../assets/exclamation.svg";
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Footer.js';
import { AUTH_TOKEN, ID, USER } from '../../constants';

function Home() {
  let navigate = useNavigate();
  let token = localStorage.getItem(AUTH_TOKEN);
  let id = localStorage.getItem(ID);
  let user = localStorage.getItem(USER);

  const isTokenValidFormat = (token) => {
    if (!token) {
      return false;
    }
    const splittedToken = token.split('.');
    if (splittedToken.length !== 3) {
      return false;
    }
    const [header, payload, signature] = splittedToken;
    if (!header || !payload || !signature) {
      return false;
    }
    return true;
  }

  const isTokenExpired = (token) => {
    console.log("token: ", token);
    if (!token) {
      return true;
    } else {
      const expiry = JSON.parse(atob(token.split('.')[1])).exp;
      return Math.floor(new Date().getTime() / 1000) >= expiry;
    }
  }



  useEffect(() => {
    if (!token || !id || !user) {
      localStorage.clear();
    } 
    if (isTokenValidFormat(token) === false || isTokenExpired(token) === true) {
      localStorage.clear();
    }
  }, [token, user, id])

  return (
    <>
      <div className='home'>
        <Navbar />
        <div className='title-home'>
          <div className='title'>
            <p style={{ color: "#5DDE94" }} >Leukidemia </p>
            <p>Vous aide à comprendre la leucémie</p>
          </div>
          <img alt={"tmp"} className='image' src={homeLogo} />
        </div>
        <div className='home-information-forum'>
          <div className='excalmation-circle'>
            <img className='exclamation' src={exclamation} alt='exclamation' />
          </div>
          <p className='text'>choisissez le sujet pour accéder
            à son forum de questions</p>
        </div>
        <div className='all-card'>
          <div className='card-home'>
            <div className='card-position-text'>
              <p className='card-title'>
                Réalité virtuelle
              </p>
              <p className='card-text'>Direction vers le forum pour la Réalité virtuelle. Vous pouvez poser des questions sur notre réalité virtuelle :  leukiVR.
              </p>
              <div className='card-button' onClick={() => navigate('/forum-categorie/vr')}>Voir</div>
            </div>
          </div>
          <div className='card-home'>
            <div className='card-position-text'>
              <p className='card-title'>
                La maladie             </p>
              <p className='card-text'>Redirection vers le Forum consacré à la Maladie. Vous pouvez poser tous types de questions sur la leucémie.

              </p>
              <div className='card-button' onClick={() => navigate('/forum-categorie/maladie')}>Voir</div>
            </div>
          </div>
          <div className='card-home'>
            <div className='card-position-text'>
              <p className='card-title'>
                Maladie au quotidien              </p>
              <p className='card-text'>Redirection vers le Forum consacré à la Maladie au quotidien. Questionnez vous sur votre quotidien.

              </p>
              <div className='card-button' onClick={() => navigate('/forum-categorie/quotidien')}>Voir</div>
            </div>
          </div>
          <div className='card-home'>
            <div className='card-position-text'>
              <p className='card-title'>
                Anecdotes
              </p>
              <p className='card-text'>Redirection vers le Forum consacré aux anecdotes. Vous pouvez écrire une anecdotes

              </p>
              <div className='card-button' onClick={() => navigate('/forum-categorie/anecdotes')}>Voir</div>
            </div>
          </div>
        </div>
        <div className='project'>
          <div className='project-text'>
            <p className='project-title'>Le projet</p>
            <p className='text'>Notre projet a pour objectif d’aider les enfants atteints de leucémie à comprendre leur maladie grâce à une expérience immersive en réalité virtuelle. Cette solution sera accompagnée d’une application mobile destinée aux parents des patients, leur permettant de suivre l’avancée du traitement et d’un forum pour communiquer notamment avec des professionnels de santé.</p>
          </div>
          <img alt={"tmp"} className='image' src={homeLogo} />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Home