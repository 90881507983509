import React from "react";
import "./BasicButton.scss";

export default function BasicButton(props) {
    return (
        <div style={{ width: props.widthButton !== null ? props.widthButton : "100%" }} className={`basic-button status--${props.statusButton}`} onClick={(e) => {
            props.setState(1);
            props.element({ variables: { input: props.state } });
        }}>
            <p className="text-button">
                {props.text}
            </p>
        </div >
    );
}