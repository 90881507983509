import SidebarMenu from 'react-bootstrap-sidebar-menu';
import * as Icon from 'react-feather';

export default function SideNav() {
return (
    <SidebarMenu
    exclusiveExpand={true}
    rtl={true}
    expand="md"
    hide="md"
    className="d-flex flex-column justify-content-start">
        <SidebarMenu.Header>
            <SidebarMenu.Brand>
            {/* Your brand icon */}
            Leukidemia
            </SidebarMenu.Brand>
            {/* <SidebarMenu.Toggle /> */}
        </SidebarMenu.Header>
        <SidebarMenu.Body className="mt-4">
            <SidebarMenu.Nav className="d-flex flex-column">
                <SidebarMenu.Nav.Link  href="/">
                    <SidebarMenu.Nav.Icon>
                    <Icon.Home size="20"/>
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                        Home
                    {/* Menu item title */}
                    </SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
                <SidebarMenu.Nav.Link className="mt-2"  href="/forum">
                    <SidebarMenu.Nav.Icon>
                    <Icon.BookOpen size="20"/>
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                        Forum
                    {/* Menu item title */}
                    </SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
                <SidebarMenu.Nav.Link className="mt-2" href="/profile">
                    <SidebarMenu.Nav.Icon>
                    <Icon.User size="20"/>
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                        Profile
                    {/* Menu item title */}
                    </SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
                <SidebarMenu.Nav.Link className=" mt-2" href="/register">
                    <SidebarMenu.Nav.Icon>
                    {/* Submenu item icon */}
                    <Icon.LogIn/>
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                        Login
                    {/* Submenu item title */}
                    </SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
                <SidebarMenu.Nav.Link className=" mt-2" href="/login">
                    <SidebarMenu.Nav.Icon>
                    {/* Submenu item icon */}
                    <Icon.LogOut/>
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                        Logout
                    {/* Submenu item title */}
                    </SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
            </SidebarMenu.Nav>
        </SidebarMenu.Body>
    </SidebarMenu>)
}