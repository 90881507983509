import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Profile from './pages/Profile';
import Home from './pages/Home/Home';
import ErrorPage from './pages/ErrorPage';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import Forum from './pages/Forum/forum'
import ResetPassword from './pages/ResetPassword/ResetPassword/ResetPassword'
import BackGround from './pages/BackGround/BackGround';
import EnterEmail from './pages/ResetPassword/EnterEmail/EnterEmail';
import ForumCategories from './pages/Forum/forum-categorie';
import VrGuide from './pages/VrGuide/VrGuide';
import MyModal from './components/form/Modal/Modal';
import PageForm from './pages/PageForm/PageForm';
import DataPage from './pages/DataPage/DataPage';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='profile' element={<Profile />} />
      <Route path='forum' element={<Forum />} />
      <Route path='*' element={<ErrorPage />} />
      <Route path='/login' element={<Login />} />
      <Route path='/register' element={<Register />} />
      <Route path='/forum' element={<Forum />} />
      <Route path='*' element={<ErrorPage />} />
      <Route path='/reset-pa  ssword/:token' element={<ResetPassword />} />
      <Route path='/background' element={<BackGround />} />
      <Route path='/enter-email' element={<EnterEmail />} />
      <Route path='/forum-categorie/:categorie' element={<ForumCategories />} />
      <Route path='/vr-guide' element={<VrGuide />} />
      <Route path='/forum/page-form/:token' element={<PageForm />} />
      <Route path='/modal' element={<MyModal />} />
      <Route path='/datapage' element={<DataPage />} />
    </Routes>
  );
}

export default App;
