import React, { useEffect } from 'react'
import logoVr from '../assets/logoVr.svg'
import { gql, useQuery, useMutation } from "@apollo/client";
import editLogo from "../assets/edit.png";
import { useNavigate } from "react-router-dom";
import { USER } from "../constants";
import './profile.scss';
import Tabs from '../components/Tabs/Tabs'
import Navbar from '../components/navbar/navbar';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import * as moment from 'moment'

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
let newMail = '';
let newUsername = '';

export default function Profile() {
  let navigate = useNavigate();
  const [isGood, setIsGood] = useState(false);
  const [show, setShow] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [file, setFile] = useState(null);

  const handleShow = () => setShow(true);
  const handleCloseImageModal = () => setShowImageModal(false);
  const handleShowImageModal = () => setShowImageModal(true);

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);
    setFile(acceptedFiles[0]);
  }, []);

  const UPDATE_USER = gql`mutation editUser($input: UpdateUserInput!) {
    editUser(data: $input) {
      email,
      username,
      id,
    }
  }`;

  const UPLOAD_IMAGE = gql`
      mutation updateProfilePicture($file: Upload!) {
        updateProfilePicture(file: $file) {
          imageURL
        }
      }
    `;
  
  const DELETE_USER = gql`mutation deleteUser($input: Int!) {
    deleteUser(id: $input)
  }`;


  const [updateUser] = useMutation(UPDATE_USER);
  const [uploadImage] = useMutation(UPLOAD_IMAGE);
  const [deleteUser] = useMutation(DELETE_USER);

  const handleDelete = async(id) => {
    await deleteUser({variables: {input:id } });
  }
  const handleSave = async () => {
    if (newMail.length > 0 && !emailRegex.test(newMail)) {
      alert('Veuillez entrer un email valide');
      return;
    }
    if (newUsername.length > 0 && (newUsername.length < 4 || newUsername > 20)) {
      alert("Veuillez renseigner un nom d'utilisateur entre 4 et 20 caractères")
      return;
    }
    try {
      const res = await updateUser({ variables: { input: { id: id, email: newMail != '' ? newMail : mail, username: newUsername != '' ? newUsername : username } } });
      console.log('toto', res.data.editUser);
      if (res.data && res.data.editUser) {
        localStorage.setItem(USER, res.data.editUser.username);
        setShow(false);
      }
    } catch (error) {
      alert(error);
    }
  };

  const handleSaveImage = async () => {
    await uploadImage({
      variables: { file: file }, context: {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth-token")
        }
      }
    });
    window.location.reload(false);
    handleCloseImageModal();
  };

  const USER_QUERY = gql`query findOneUserByUsername($input: String!) {
    findOneUserByUsername(username: $input) {
      id
      email,
      role,
      username,
      url_picture,
      created_at,
      posts {
        created_at,
        category,
        title,
        message,
        likes,
        id,
      },
      comments{
        created_at,
        comment,
        id,
      },
    }
  }`;

  const { loading, error, data } = useQuery(USER_QUERY, { variables: { input: localStorage.getItem(USER) } });
  if (loading) return 'Loading...';
  if (error || !data.findOneUserByUsername) {
    navigate('/login');
    return <></>;
  }

  let mail = data.findOneUserByUsername.email;
  let role = data.findOneUserByUsername.role;
  let username = data.findOneUserByUsername.username;
  let date = moment(data.findOneUserByUsername.created_at).format("DD-MM-YYYY");
  let id = data.findOneUserByUsername.id;
  return (
    <div>
      <div className="profile-page">
        <Navbar />
        <div className='profile'>
          <div className="profile-picture">
          <div className="edit">
              <img className='img-edit' width={40} height={40} src={editLogo} onClick={handleShowImageModal} alt="Edit" />
            </div>
            <div className='data-profile'>
              <div className="img-profile">
                <img src={data.findOneUserByUsername.url_picture ? data.findOneUserByUsername.url_picture : logoVr} alt="Profile " />
              </div>
              <div className='button-profile'>
                <div className='--text' style={{marginTop: "20px"}} onClick={(e) => {
                  e.preventDefault();
                  if (window.location.pathname !== "/profile") {
                    navigate('/profile');
                  }
                }}>{username}</div>
                <div className='--text' style={{ cursor: "pointer" }} onClick={(e) => {
                  e.preventDefault();
                  if (window.location.pathname !== "/") {
                    navigate('/');
                  }
                }}>Page d'accueil</div>
                <div style={{marginBottom: "20px"}} className='--text' onClick={(e) => {
                  e.preventDefault();
                  if (window.location.pathname !== "/logout") {
                    localStorage.clear();
                    navigate('/');
                  }
                }}>Deconnexion</div>
                <div style={{marginBottom: "20px"}} className='--text' onClick={(e) => {
                  e.preventDefault();
                  if (window.location.pathname !== "/delete") {
                    handleDelete(id);
                    localStorage.clear();
                    navigate('/');
                  }
                }}>Supprimer le compte</div>
              </div>
            </div>
          </div>
          <div className="profile-info">
            <div className="title">
              <h2 className='title--text'>Informations</h2>
              <div className="img-1">
                <img className='img-edit' src={editLogo} onClick={handleShow} alt="Edit" />
              </div>
            </div>
            <div className='text-info'>
              <p className='text-info--text'><span className='title-info'>Nom de l'utilisateur:</span> {username}</p>
              <p className='text-info--text'><span className='title-info'>Email:</span>{mail}</p>
              <p className='text-info--text'><span className='title-info'>Role:</span> {role}</p>
              <p className='text-info--text'><span className='title-info'>Date:</span> {date}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='Tabs'>
        <Tabs />
      </div>
      <Modal show={showImageModal} onHide={handleCloseImageModal}>
        <Modal.Header closeButton>
          <Modal.Title>Mise a jour de l'image utilisateur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropzone onDrop={onDrop} multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {file ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Preview"
                    style={{ maxWidth: '100%', maxHeight: '200px' }}
                  />
                ) : (
                  <p>Déposez l'image ici ou cliquez pour sélectionner</p>
                )}
              </div>
            )}
          </Dropzone>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseImageModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveImage}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={() => {setShow(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Mise a Jour du Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Nom d'utilisateur</Form.Label>
              <Form.Control
                style={{
                  'backgroundColor': 'rgba(0, 0, 0, 0.10)',
                }}
                type="text"
                placeholder={username}
                onChange={(e) => (newUsername = e.target.value)}
              />
            </Form.Group>
          <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                style={{
                  'backgroundColor': 'rgba(0, 0, 0, 0.10)',
                }}
                type="email"
                placeholder={mail}
                onChange={(e) => (newMail = e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSave}>
            Sauvegarder les modifications
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}