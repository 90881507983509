import React, { useState } from "react";
import "./BoxExpand.scss";

export default function BoxExpand({ title, paragraph, subTitle, link, isVideo }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleBox = () => {
        setIsOpen(prevState => !prevState);
    };

    return (
        <div className={`box-expand ${isOpen ? 'open' : ''}`} onClick={toggleBox}>
            <div className="title">
                {title}
            </div>
            <div className="content">
                <div className="paragraph-pos">
                    {paragraph}
                </div>
                <div className="sub-title">
                    {subTitle}
                </div>
                {isVideo ? (
                    <div className="video">
                        <iframe
                            title="Embedded youtube"
                            src={`${link}?autoplay=1&mute=1`}
                            style={{
                                border: 'none',
                                width: '750px',
                                height: '415px'
                            }}
                            allowFullScreen
                        />
                    </div>
                ) : <div className="link">
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        {link}
                    </a>
                </div>
                }
            </div>
        </div>
    );
}