import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Navbar from '../../components/navbar/navbar.js';
import "./forum-categorie.scss";
import { gql, useLazyQuery } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import MyModal from "../../components/form/Modal/Modal.js";
import LoadingIndicator from "../../components/LoadingIndicator/loadingIndicator.js";
import { setForumBegin, setForumEnd } from "../../store/forum";
import * as moment from 'moment'
import fond from "../../assets/fond_1.svg";
import arrow from "../../assets/arrow.svg";
import Search from "../../assets/Search.svg";
import AddRing from "../../assets/Add_ring.svg";
import Return from "../../assets/Return.svg";
import Footer from "../../components/Footer/Footer.js";



export default function ForumCategorie() {
    let { categorie } = useParams();
    const [table, setTable] = useState([]);
    const dispatch = useDispatch();
    const [value, setValue] = useState("");
    const [nav, setNav] = useState("");
    const [pagination, setPagination] = useState(0);
    let navigate = useNavigate();
    const forum = useSelector((state) => state.forum.forum);
    const [prev, setPrev] = useState(false);
    const [next, setNext] = useState(false);
    const [test, setText] = useState("");

    const FORUM_CATEGORIE = gql`query getPosts($input: FetchPostFilter) {
        getPosts(fetchPostFilter: $input) {
        page {
            edges {
                cursor,
                node {
                    id
                    category
                    title
                    message
                    userId
                    created_at
                    updated_at
                    user {
                        username
                    }
                }
            },
            pageInfo {
                startCursor,
                endCursor,
                hasPreviousPage,
                hasNextPage,
            },
        },
        pageData {
            count
        }
    }
   }`;

    const USER_QUERY = gql`query findOneUserByUsername($input: String!) {
    findOneUserByUsername(username: $input) {
      id
      email,
      role,
      username,
      url_picture,
      created_at,
      posts {
        created_at,
        category,
        title,
        message,
        likes,
        id,
      },
    }
  }`;


    function goodEnum(categorie) {
        switch (categorie) {
            case "vr":
                return "VR";
            case "anecdotes":
                return "ANECDOTE";
            case "quotidien":
                return "DAYLILIFE";
            case "maladie":
                return "QUESTIONS";
            default:
                return "VR";
        }
    }

    const redirectToHome = () => navigate("/");
    const [loadGreeting, { data, loading, error }] = useLazyQuery(FORUM_CATEGORIE);

    useEffect(() => {
        loadGreeting({
            variables: {
                input: {
                    category: goodEnum(categorie),
                    title: value === "" ? undefined : value,
                    connectionArgs: {
                        count: 5,
                        after: next && forum.forumEnd ? forum.forumEnd : null,
                        before: prev && forum.forumBegin ? forum.forumBegin : null,
                    }
                }
            }
        }).then
            (res => {
                setNext(false);
                setPrev(false);
                setTable(res.data.getPosts.page.edges);
                setNav(res.data.getPosts.page.pageInfo);
            }
            ).catch(err => {
            });
    }, [categorie, value, pagination, forum.forumEnd, forum.forumBegin, loadGreeting, next, prev]);

    const [openModal, setOpenModal] = useState(false);


    let toto = openModal ? <div className="dark-bg">
        <div onClick={(e) => {
            setOpenModal(!openModal);
        }} className="close-item">Close</div>
        <MyModal modalClose={setOpenModal} />
    </div> : null;

    const checkLogin = () => {
        if (!localStorage.getItem("auth-token") || !localStorage.getItem("id")) {
            alert("Vous devez être connecté pour commenter");
            setOpenModal(false)
        }
    };

    function nameCategorie(categorie) {
        switch (categorie) {
            case "vr":
                return "la réalite virtuelle";
            case "anecdotes":
                return "les anecdotes";
            case "quotidien":
                return "le quotidien";
            case "maladie":
                return "les questions";
            default:
                return "VR";
        }
    }
    if (loading) {
        return <LoadingIndicator />;
    }

    if (error) return alert("Une erreur est survenue");


    return (
        <>
            <div className="page-form-category">
                <Navbar />
                {toto}
                <div style={{ cursor: "pointer" }} className="position-form" >
                    <div className="round-form" onClick={(e) => {
                        redirectToHome()
                    }}  >
                        <img className="arrow" src={arrow} alt="arrow" onClick={(e) => {
                            redirectToHome()
                        }} />
                    </div>
                    <p className="text" onClick={(e) => {
                        redirectToHome()
                    }} >
                        Forum -{">"} {nameCategorie(categorie)}
                    </p>
                </div>
                <div className="all-categorie text">
                    <p className={`${categorie === "vr" ? "categorie" : "none"}`} onClick={(e) => {
                        navigate("/forum-categorie/vr")
                    }} style={{ cursor: "pointer" }} >Réalité virtuelle </p>
                    <p className={`${categorie === "maladie" ? "categorie" : "none"}`} onClick={
                        (e) => {
                            navigate("/forum-categorie/maladie")
                        }
                    } style={{ cursor: "pointer" }}>La maladie </p>
                    <p className={`${categorie === "quotidien" ? "categorie" : "none"}`} onClick={(e) => {
                        navigate("/forum-categorie/quotidien")
                    }} style={{ cursor: "pointer" }}>Maladie au quotidien  </p>
                    <p className={`${categorie === "anecdotes" ? "categorie" : "none"}`} onClick={(e) => {
                        navigate("/forum-categorie/anecdotes")
                    }} style={{ cursor: "pointer" }}>Anecdotes</p>
                </div>

                <div className="description-categorie">
                    <p className="text">
                        Forum pour discuter de : {nameCategorie(categorie)}
                    </p>
                </div>
                <div className="navigate-forum">
                    <img className="AddRing" src={AddRing} alt="AddRing" />
                    <div style={{ cursor: "pointer " }} className="new-subject" onClick={(e) => {
                        setOpenModal(!openModal);
                        checkLogin();
                    }}>
                        Nouveau Sujet
                    </div>
                    <div className="recherche">
                        <img className="Search" src={Search} alt="Search" />
                        <input className="input-recherche" placeholder="Rechercher" type="text" value={value} onChange={(e) => {
                            setValue(e.target.value);
                        }} />
                    </div>
                </div>
                <div className="rectangle-answer">
                    {table ? table.map((item, index) => {
                        return (
                            <div key={index} className="rectangle-form" style={{ cursor: "pointer" }} onClick={(e) => {
                                navigate(`/forum/page-form/${item.node.id}`)
                            }}>
                                <div className="information-form">
                                    <p className="title">
                                        {item.node.title}
                                    </p>
                                    <div className="number-answer">
                                        <img className="Return" src={Return} alt="Return" />
                                        <p className="response"> Réponses</p>
                                    </div>
                                </div>
                                <div className="information-form">
                                    <p className="name">
                                        {item.node.user.username}
                                    </p>
                                    <p className="date">
                                        {moment(item.node.updated_at).format("DD-MM-YYYY")}
                                    </p>
                                </div>
                            </div>
                        )
                    }) : <div className="nothing">
                        Il n'y a rien sur le forum actuellement
                    </div>
                    }{
                        (nav?.hasNextPage === true || nav?.hasPreviousPage === true) &&
                        <div className="arrow-pos">
                            <img style={{ cursor: "pointer" }} className="arrow-left" src={arrow} alt="arrow" onClick={(e) => {
                                if (nav.hasPreviousPage === true) {
                                    dispatch(setForumEnd(data.getPosts.page.pageInfo.endCursor));
                                    dispatch(setForumBegin(data.getPosts.page.pageInfo.startCursor));
                                    setPrev(true);
                                    setPagination(!pagination);

                                }
                            }} />
                            {(nav?.hasNextPage === true) &&
                                <img className="arrow-right" style={{ transform: "rotate(180deg)", cursor: "pointer" }} src={arrow} alt="arrow"
                                    onClick={(e) => {
                                        if (nav.hasNextPage === true) {
                                            dispatch(setForumEnd(data.getPosts.page.pageInfo.endCursor));
                                            dispatch(setForumBegin(data.getPosts.page.pageInfo.startCursor));
                                            setNext(true);
                                            setPagination(!pagination);
                                        }
                                    }}
                                />
                            }
                        </div>
                    }
                </div>
                <Footer />
            </div >
        </>
    );
}