import { createSlice } from '@reduxjs/toolkit'

const initialState ={
    name: 'forum',
    forum: {
        forumBegin: "",
        forumEnd: "",
    }
}

export const forumSlice = createSlice({
    name: 'forum',
    initialState,
    reducers: {
        setForumBegin: (state, action) => {
            state.forum.forumBegin = action.payload
        },
        setForumEnd: (state, action) => {
            state.forum.forumEnd = action.payload
        }
    }
})

export const { setForumBegin, setForumEnd } = forumSlice.actions