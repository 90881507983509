import React, { useState } from "react";
import "./BoxGuide.scss";

export default function BoxGuide({ title, paragraph, image }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleBox = () => {
        setIsOpen(prevState => !prevState);
    };

    return (
        <div className={`box-guide ${isOpen ? 'open' : ''}`} onClick={toggleBox}>
            <div className="title">
                {title}
            </div>
            <div className="content">
                <div className="paragraph-pos">
                    {paragraph}
                </div>
                {
                image ? (
                    <div className="image">
                        <img
                            width={500}
                            src={image}
                            alt="guide-pictur"
                        />
                    </div>
                ) : null
            }
            </div>
            </div>
    );
}