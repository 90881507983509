import React from "react";
import "./BackGround.scss";

export default function BackGround() {
    return (
        <div className="background">
            <div className="first-circle"></div>
            <div className="second-circle"></div>
            <div className="third-circle"></div>
            <div className="fourth-circle"></div>
            <div className="five-circle"></div>
            <div className="background-image"></div>
        </div>
    );
}