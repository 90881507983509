import "./Modal.scss";
import React from "react";
import { gql, useMutation } from "@apollo/client";
import { useEffect } from "react";
import LoadingIndicator from "../../LoadingIndicator/loadingIndicator";

export default function MyModal(props) {

    const [title, setTitle] = React.useState("");
    const [categorie, setCategorie] = React.useState("Vr");
    const [message, setMessage] = React.useState("");
    const [isTitleLengthValid, setIsTitleLengthValid] = React.useState(true);


    const CREATE_POST = gql`mutation createPost($input: CreatePostInput!) {
        createPost(createPostInput: $input) {
        id
        category
        title
        comments {
            id
            userId  
            created_at
            updated_at
        }
        likes
        message
        updated_at
        }
    }`;

    const [theMutation, { loading, error, data }] = useMutation(CREATE_POST, {
        onCompleted: (data) => {
            props.modalClose(false);
            window.location.reload();
        },
        onError: (error) => {
            console.log(error);
        }
    }
    );

    function goodEnum(categorie) {
        switch (categorie) {
            case "Vr":
                return "VR";
            case "Anecdotes":
                return "ANECDOTE";
            case "Quotidien":
                return "DAYLILIFE";
            case "Maladie":
                return "QUESTIONS";
            default:
                return "VR";
        }
    }

    const sendDatas = () => {
        if (title && message) {
            theMutation({
                variables: {
                    input: {
                        userId: Number(localStorage.getItem("id")),
                        title: title,
                        message: message,
                        category: goodEnum(categorie)
                    }
                }
            });
        } else {
            props.modalClose(true);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.className === "modal-backgrounds") {
                props.modalClose(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [props]);

    return (
        <>
            <div className="modal-backgrounds"></div>
            <div className="my-modal">
                <div className="tmp-title">
                    <input maxLength={28} className={(title && isTitleLengthValid) ?     `input-title` : `input-title error`} onChange={(e) => {
                        setTitle(e.target.value);
                    }}></input>
                    <select className="type-forum" onChange={(e) => {
                        setCategorie(e.target.value);
                    }}>
                        <option>Vr</option>
                        <option>Anecdotes</option>
                        <option>Maladie</option>
                        <option>Quotidien</option>
                    </select>
                </div>
                <textarea maxLength={250} className={message ? 'input-text-forum' : 'input-text-forum error'} size={"100"} type={"text"} onChange={(e) => {
                    setMessage(e.target.value);
                }}></textarea>
                <div className="button-submit-forum" onClick={(e) => {
                    e.preventDefault();
                    sendDatas();
                }}>Création du post</div>
            </div>
        </>
    );
}