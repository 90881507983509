import React, { useState } from 'react';
import { gql, useQuery } from "@apollo/client";
import { USER } from "../../../src/constants";
import * as moment from 'moment'

const USER_QUERY = gql`query findOneUserByUsername($input: String!) {
    findOneUserByUsername(username: $input) {
      email,
      role,
      username,
      url_picture,
      posts {
        created_at,
        category,
        title,
        message,
        likes,
        id,
      },
      comments{
        created_at,
        comment,
        id,
      },
    }
  }`;

const Tabs = () => {

    const { data } = useQuery(USER_QUERY, { variables: { input: localStorage.getItem(USER) } });
    let comments = data.findOneUserByUsername.comments;
    let posts = data.findOneUserByUsername.posts;

    const pathPost = (path) => {
      switch (path) {
        case "VR":
          return "vr";
        case "ANECDOTE":
          return "anecdote";
        case "DAYLILIFE":
          return "quotidien";
        case "QUESTIONS":
          return "maladie";
        default:
          return "VR";
      }
    }

    const [currentTab, setCurrentTab] = useState('1');
    const tabs = [
        {
            id: 1,
            tabTitle: 'Posts',
            content: <div className='information-card'>
            <div className='card'>
              {
                posts ? posts.map((item, index) => {
                  return (<div key={index}>
                    <div className='post-part'>
                      <div className='text-title'>
                        <a href={"/forum/page-form/" + item.id} style={{wordBreak: 'break-word'}}>{item.title}</a>
                      </div>
                      <div className='text-title'>
                      <div>
                          <b>Catégorie</b>: {pathPost(item.category)}
                        </div>
                        <div>
                          <b>Date</b>: {moment(item.created_at).format("DD-MM-YYYY")}
                        </div>
                        <div>
                          <b>J'aimes</b>: {item.likes}
                        </div>
                      </div>
                    </div>
                    <div className='right-black'></div>
                  </div>)
                }
                ) : null}
            </div>
          </div>
        },
        {
            id: 2,
            tabTitle: 'Commentaires',
            content: <div className='information-card'>
            <div className='card'>
              {
                comments ? comments.map((item, index) => {
                  return (<div key={index} >
                    <div className='post-part'>
                      <div className='text-title'>
                        <div style={{wordBreak: 'break-word'}}>
                          {item.comment}
                        </div>
                      </div>
                      <div className='text-title'>
                        <div>
                          <b>Date</b>: {moment(item.created_at).format("DD-MM-YYYY")}
                        </div>
                      </div>
                    </div>
                    <div className='right-black'></div>
                  </div>)
                }
                ) : null}
            </div>
          </div>
        }
    ];

    const handleTabClick = (e) => {
        setCurrentTab(e.target.id);
    }

    return (
        <div className='container'>
            <div className='tabs'>
                {tabs.map((tab, i) =>
                    <button key={i} id={tab.id} disabled={currentTab === `${tab.id}`} onClick={(handleTabClick)}>{tab.tabTitle}</button>
                )}
            </div>
            <div className='content'>
                {tabs.map((tab, i) =>
                    <div key={i}>
                        {currentTab === `${tab.id}` && <div>{tab.content}</div>}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Tabs;