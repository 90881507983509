import react from 'react';
import './DataPage.scss';
import Navbar from '../../components/navbar/navbar.js';
import BoxExpand from '../../components/BoxExpand/BoxExpand';
import arrow from '../../assets/arrow.svg';
import { useNavigate } from 'react-router-dom';
import fond from "../../assets/fond_1.svg";
import home from "../../assets/home.svg";
import Footer from '../../components/Footer/Footer.js';

export default function DataPage() {

    const [categorie, setCategorie] = react.useState("Article Scientifique");
    let navigate = useNavigate();

    return (
        <>
            <Navbar />
            <div className='datapage'>
                <div className='datapage-container'>
                    <div style={{ cursor: "pointer" }} className="position-form" onClick={(e) => {
                        navigate("/")
                    }} >
                        <div className="round-form" >
                            <img className="arrow" src={arrow} alt="arrow" />
                        </div>
                        <p style={{ margin: "0px" }} className="text">
                            Forum -{">"} {categorie}
                        </p>
                    </div>
                    <div className='all-categorie text'>
                        <p className={`${categorie === "Article Scientifique" ? "categorie" : "none"}`} onClick={(e) => {
                            setCategorie("Article Scientifique");
                        }} style={{ cursor: "pointer" }} > Article Scientifique </p>
                        <p className={`${categorie === "Guide pour les patients" ? "categorie" : "none"}`} onClick={(e) => {
                            setCategorie("Guide pour les patients");
                        }} style={{ cursor: "pointer" }}>Guide pour les patients</p>
                        <p className={`${categorie === "Video Explicative" ? "categorie" : "none"}`} onClick={(e) => {
                            setCategorie("Video Explicative");
                        }} style={{ cursor: "pointer" }}>Vidéo Explicative</p>
                    </div>
                    {categorie === "Article Scientifique" &&
                        <div className="content-wrapper">
                            <BoxExpand
                                title="Cancers pédiatriques : pourquoi certaines leucémies touchent uniquement les enfants"
                                paragraph="Une collaboration de recherche européenne a exploré pourquoi certaines leucémies agressives affectent spécifiquement les très jeunes enfants. Les chercheurs ont découvert qu'une altération génétique particulière, présente dans 30 % des cas de leucémies, peut induire rapidement des leucémies agressives si elle est activée dans les cellules hématopoïétiques du fœtus. Cette altération génétique est moins susceptible de causer une leucémie chez les adultes. Lorsque cette altération est bloquée, les cellules cancéreuses peuvent retourner à un état normal, ce qui suggère des voies pour le développement de nouvelles thérapies ciblées pour améliorer le traitement chez les jeunes patients."
                                subTitle="Le lien du site"
                                link="https://www.ligue-cancer.net/articles/cancers-pediatriques-pourquoi-certaines-leucemies-touchent-uniquement-les-enfants"
                                isVideo={false} />
                            <BoxExpand
                                title="Leucémies aiguës myéloïdes : la piste du fer"
                                paragraph="Des chercheurs français et australiens ont élaboré une nouvelle approche pour traiter les leucémies aiguës myéloïdes (LAM) en utilisant une molécule nommée ironomycine. Cette molécule perturbe le fonctionnement des cellules cancéreuses en séquestrant le fer, essentiel à plusieurs processus biologiques, réduisant ainsi l’énergie des cellules leucémiques et induisant leur mort par un mécanisme distinct de l’apoptose. L'association de l'ironomycine avec une thérapie ciblée s'est révélée efficace pour éliminer les cellules leucémiques résistantes à la thérapie ciblée seule, offrant une nouvelle piste prometteuse pour le traitement des LAM."
                                subTitle="Le lien du site"
                                link="https://www.ligue-cancer.net/articles/leucemies-aigues-myeloides-la-piste-du-fer"
                                isVideo={false} /><BoxExpand
                                title="Leucémies aiguës myéloïdes : la recherche contre les rechutes"
                                paragraph="L’équipe de Jean-Emmanuel Sarry a étudié la résistance des leucémies aiguës myéloïdes (LAM) à la chimiothérapie, se concentrant sur l’activité des mitochondries des cellules cancéreuses. Ils ont développé un MitoScore, un biomarqueur mesurant l’activité mitochondriale, aidant à prédire la réponse au traitement combiné de chimiothérapie et thérapie ciblée. Les cellules cancéreuses adaptent l’activité de leurs mitochondries pour résister au traitement, mais en utilisant un inhibiteur mitochondrial, la résistance pourrait être contrecarrée, réduisant le risque de rechute. Ces découvertes ouvrent des perspectives pour des traitements personnalisés et plus efficaces contre les LAM."
                                subTitle="Le lien du site"
                                link="https://www.ligue-cancer.net/articles/leucemies-aigues-myeloides-la-recherche-contre-les-rechutes-1"
                                isVideo={false} /><BoxExpand
                                paragraph="Des recherches soutenues par la Ligue ont révélé que les survivants d'un cancer pédiatrique présentent un risque quadruplé de développer des leucémies secondaires, persistant même 20 ans après la première maladie. Les survivants de lymphome d’Hodgkin sont particulièrement à risque. Ces découvertes accentuent l'importance d'un suivi adapté et la nécessité de directives claires pour gérer les risques à long terme chez les survivants d’un cancer pédiatrique."
                                title="Leucémies secondaires après un cancer pédiatrique, quel risque ?"
                                subTitle="Le lien du site"
                                link="https://www.ligue-cancer.net/articles/leucemies-secondaires-apres-un-cancer-pediatrique-quel-risque"
                                isVideo={false} /></div>
                    }
                    {
                        categorie === "Guide pour les patients" &&
                        <div className="content-wrapper">
                            <BoxExpand
                                title="Leucémie myéloide chronique "
                                paragraph="Ce guide, préparé par Le Fonds Anticancer en collaboration avec l’ESMO, vise à aider les patients et leurs proches à comprendre et naviguer à travers les options de traitement de la leucémie myéloïde chronique (LMC). Il s'appuie sur les recommandations cliniques de l’ESMO, rédigé par des experts médicaux et revu par des oncologues et des représentants de patients. Les patients sont encouragés à consulter leur médecin pour choisir les tests et traitements les plus adaptés à leur condition et stade de la maladie."
                                subTitle="Le lien du site"
                                link="https://www.esmo.org/content/download/6600/115001/1/FR-LMC-Guide-pour-les-Patients.pdf"
                                isVideo={false} /><BoxExpand
                                title="Le suivi médical après la maladie"
                                paragraph="Après le traitement d'une leucémie, les patients sont en surveillance médicale pendant plusieurs années pour détecter toute rechute ou effet secondaire à long terme. Les traitements ont évolué, réduisant les séquelles, mais des complications telles que des troubles de la puberté, la stérilité et des risques cardiaques ou un second cancer peuvent survenir, nécessitant un suivi à long terme. Des programmes multidisciplinaires sont mis en place pour étudier l'impact des traitements sur les patients à long terme, tant sur le plan physique que psychosocial."
                                subTitle="Le lien du site"
                                link="https://www.fondation-arc.org/cancer/leucemie-enfant/apres-la-maladie"
                                isVideo={false} />
                            <BoxExpand
                                title="Guide pour la famille"
                                paragraph="Votre enfant est hospitalisé suite au diagnostic d’une maladie cancéreuse ou hématologique grave. Cette situation nouvelle est déstabilisante et peut générer des inquiétudes bien compréhensibles.
                            Cependant, sachez que d’importants progrès ont été réalisés ces
                            dernières années, en particulier dans la prise en charge des jeunes
                            patients et ceci grâce au regroupement des compétences dans des
                            centres spécialisés reconnus au niveau national et international. "
                                subTitle="Le lien du site"
                                link="https://www.hug.ch/sites/hde/files/documents/guide_onco_pediatrie_0.pdf"
                                isVideo={false} /></div>
                    }
                    {categorie === "Video Explicative" &&
                        <div className="content-wrapper">
                            <BoxExpand
                                title="La leucémie chez l'enfant et l'adolescent"
                                paragraph="Cette vidéo vous aidera à mieux comprendre les leucémies chez les enfants et adolescents."
                                subTitle="Le lien de la vidéo"
                                link="https://www.youtube.com/embed/jRO-bWVLd8U?si=mJe3WX2zKbvcoJEC"
                                isVideo={true} /><BoxExpand
                                title="C'est quoi le cancer ?"
                                paragraph="C'est quoi le cancer ? Le commentaire explicatif est toujours drôle, le dessin est léger et espiègle. L'intention est d'aider l'enfant à construire son propre raisonnement et à obtenir les clés qui lui permettront de se forger sa propre opinion."
                                subTitle="Le lien de la vidéo"
                                link="https://www.youtube.com/embed/ZTrMC36Tag0?si=tPAgKnq_hsdybTb2"
                                isVideo={true} />
                            <BoxExpand
                                title="Il était une fois… la Vie - Le système lymphatique"
                                paragraph="Assez méconnu, ce vaste réseau du corps humain permet, grâce à la lymphe, de transporter tous les matériaux vers les usines du corps. Gare aux intrus infectieux, la place est bien gardée..."
                                subTitle="Le lien de la vidéo"
                                link="https://www.youtube.com/embed/EuDQeArJDuA?si=tmMdQBYJy3fnxBM2"
                                isVideo={true} /></div>
                    }
                </div>
                <Footer />
            </div >
        </>
    );
}
