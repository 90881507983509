import { gql, useLazyQuery } from "@apollo/client";
import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { AUTH_TOKEN, USER, ID } from "../../../constants";
import './LoginForm.scss'


const LOGIN = gql`query login($input: LoginUserInput!) {
  login(loginUserInput: $input) {
    user {
      username
      email
      id
    }
    access_token
  }
}
`;

function LoginForm() {
  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();

  const [details, setDetails] = useState({ username: "", password: "", stayConnected: false });
  const [errMsg, setErrMsg] = useState('');

  const [getLogin] = useLazyQuery(LOGIN,
    {
      variables: {
        input: {
          username: details.username,
          password: details.password,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
      },
      onCompleted: (data) => {
        localStorage.setItem(AUTH_TOKEN, data.login.access_token);
        localStorage.setItem(USER, data.login.user.username);
        localStorage.setItem(ID, data.login.user.id);
        navigate('/');
      },
      onError: (err) => {
        if (err.graphQLErrors[0] !== undefined && err.graphQLErrors[0].message !== null) {
          if (err.graphQLErrors[0].message === "Cannot return null for non-nullable field Query.login.")
            setErrMsg("Le mot de passe est incorect");
          else if (err.graphQLErrors[0].message === "Cannot read properties of null (reading 'password')")
            setErrMsg("le nom d'utilisateur est incorect");
          else
            setErrMsg(err.graphQLErrors[0].message);
        } else {
          setErrMsg("Une erreur est survenue");
        }
        errRef.current.focus();
      }
    });

  useEffect(() => {
    userRef.current.focus();
    if (localStorage.getItem(AUTH_TOKEN) && localStorage.getItem(USER) && localStorage.getItem(ID)) {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    setErrMsg('');
  }, [details])

  const submitHandler = async event => {
    event.preventDefault();
    await getLogin({
      variables: {
        input: {
          username: details.username,
          password: details.password,
        }
      }
    });
  }

  return (
    <div className="login-form">
      <form className="form" onSubmit={submitHandler}>
        <div className="form-inner">
          <h2 className="h2">Connexion</h2>
          <p ref={errRef} className={errMsg ? "form-error" : "offscreen"} aria-live="assertive">{errMsg}</p>
          <div className="form-group">
            <label className="label" htmlFor="name">Nom:</label>
            <input
              className="input"
              type="text"
              name="name"
              id="name"
              ref={userRef}
              onChange={e => setDetails({ ...details, username: e.target.value })}
              value={details.username}
              required
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="password">Mot de Passe: </label>
            <input
              className="input"
              type="password"
              name="password"
              id="password"
              ref={userRef}
              onChange={e => setDetails({ ...details, password: e.target.value })}
              value={details.password}
              required
            />
          </div>
          <div className="help-msg">
            <label>
              Rester connecter
              <input
                className="checkbox"
                type={"checkbox"}
                checked={details.stayConnected}
                onChange={e => setDetails({ ...details, stayConnected: !details.stayConnected })}
              />
            </label>
          </div>
          <input
            className="input"
            type={"submit"}
            value={"CONNEXION"}
          />
          <div className="help-msg">
            <p className="p">Pas de compte?</p>
            <Link to={'/register'}>S'inscrire</Link>
          </div>
        </div>
      </form>
    </div>
  )
}

export default LoginForm;