import React, { useState, useRef, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./RegisterForm.scss";
import { gql, useMutation } from "@apollo/client";
import { AUTH_TOKEN, USER, ID } from "../../../constants";

const REGISTER_MUTATION = gql`mutation register($input: RegisterUserInput!) {
  register(registerUserInput: $input) {
    user {
      id
      username
      email
    }
    access_token
  }
}`;

function RegisterForm() {
  const userRef = useRef();
  const errRef = useRef();

  const navigate = useNavigate();

  const [details, setDetails] = useState({ username: "", email: "", password: "", confirmPassword: "", stayConnected: false });
  const [errMsg, setErrMsg] = useState('');
  const [errorMdp, setErrorMdp] = useState(false);
  const [errorMail, setErrorMail] = useState(false);
  const [errorUsername, setErrorUsername] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);

  const [register] = useMutation(REGISTER_MUTATION, {
    variables: {
      input: {
        username: details.username,
        email: details.email,
        password: details.password,
        confirmPassword: details.confirmPassword,
      }
    },
    onCompleted: (data) => {
      localStorage.setItem(AUTH_TOKEN, data.register.access_token);
      localStorage.setItem(ID, data.register.user.id);
      localStorage.setItem(USER, data.register.user.username);
      navigate('/');
    },
    onError: (err) => {
      console.log("error: ", err.graphQLErrors);
      if (err.graphQLErrors[0].extensions.response.statusCode)
        setErrMsg(err.graphQLErrors[0].extensions.response.message);
      errRef.current.focus();
    }
  });

  useEffect(() => {
    userRef.current.focus();
    if (localStorage.getItem(AUTH_TOKEN) && localStorage.getItem(ID) && localStorage.getItem(USER)) {
      navigate('/');
    }
  }, [])

  useEffect(() => {
    setErrMsg('');
  }, [details])

  function verifyUsername() {
    if (details.username.length < 1) {
      setErrMsg("Nom d'utilisateur non valide");
      setErrorUsername(true);
      errRef.current.focus();
      return false;
    }
    return true
  }

  function verifyEmail() {
    if (details.email.length < 1) {
      setErrMsg("Email trop cours");
      setErrorMail(!errorMail);
      errRef.current.focus();
      return false;
    }
    if (!details.email.includes("@")) {
      setErrMsg("Email non valide");
      setErrorMail(!errorMail);
      errRef.current.focus();
      return false;
    }
    setErrorMail(false);
    return true;
  }

  async function submitHandler(e) {
    e.preventDefault();
    let strongRegex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$");
    if (verifyUsername() === false)
      return;
    if (verifyEmail() === false)
      return;
    if (details.password !== details.confirmPassword) {
      setErrMsg("Mots de passe différents");
      setErrorMdp(true);
      errRef.current.focus();
      return;
    } else if (!strongRegex.test(details.password)) {
      setErrMsg("Mot de passe trop faible")
      setErrorMdp(true);
      errRef.current.focus();
      alert("le mot de passe doit contenir 1 minuscule, 1 majuscule, 1 chiffre et contenir plus de 8 caractères")
      return;
    }
    setErrorMdp(false);
    register();
    setDetails({ username: "", email: "", password: "", confirmPassword: "", stayConnected: false });
  }

  return (
    <div className="register-form">
      <form className="form" onSubmit={submitHandler
      }>
        <div className="form-inner">
          <h2 className="h2">Inscription</h2>
          <p ref={errRef} className={errMsg ? "form-error" : "offscreen"} aria-live="assertive">{errMsg}</p>
          <div className="form-group">
            <label className="label" htmlFor="name">Nom d'utilisateur:</label>
            <input
              className="input"
              type="text"
              name="name"
              id="name"
              data-testid="test"
              ref={userRef}
              onChange={e => {
                setDetails({ ...details, username: e.target.value });
                e.target.setCustomValidity('');
              }}
              onInvalid={e => {
                if (e.target.value.length < 1) {
                  e.target.setCustomValidity("Veuillez entrer un nom d'utilisateur");
                }
              }} value={details.username}
              required
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="email">Email: </label>
            <input
              style={{ "backgroundColor": errorMail ? "rgb(255,0,0,0.4)" : "", "border": errorMail ? "2px solid red" : "" }}
              className="input"
              type="text"
              name="email"
              id="email"
              ref={userRef}
              onChange={e => {
                setDetails({ ...details, email: e.target.value })
                e.target.setCustomValidity('');
              }}
              value={details.email}
              onInvalid={e => {
                const emailValue = e.target.value;
                if (emailValue.length < 1) {
                  e.target.setCustomValidity("Veuillez entrer un mail");
                } else if (!emailValue.includes("@")) {
                  e.target.setCustomValidity("Veuillez entrer un mail valide");
                } else {
                  e.target.setCustomValidity("");
                }
              }}
              required
            />
          </div>
          <div className="box-information">
              <p> Le mot de passe doit contenir 1 minuscule,1 majuscule, 1 chiffre et contenir plus de 8 caractères.</p>
          </div>
          <div className="form-group">
            <label className="label" htmlFor="password">Mot de Passe: </label>
            <input
              style={{ "backgroundColor": errorMdp ? "rgb(255,0,0,0.4)" : "", "border": errorMdp ? "2px solid red" : "" }}
              className="input"
              type="password"
              name="password"
              id="password"
              data-testid="password"
              ref={userRef}
              onChange={e => {
                setDetails({ ...details, password: e.target.value });
                e.target.setCustomValidity('');
              }}
              onInvalid={e => (
                e.target.value.length < 1 ? e.target.setCustomValidity("Veuillez entrer un mot de passe") : e.target.setCustomValidity("Mot de passe trop faible")
              )}
              value={details.password}
              required
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="confirmPassword">Confirmation Mot de Passe: </label>
            <input
              style={{ "backgroundColor": errorMdp ? "rgb(255,0,0,0.4)" : "", "border": errorMdp ? "2px solid red" : "" }}
              className="input"
              type="password"
              name="confirmPassword"
              data-testid="confirmPassword"
              id="confirmPassword"
              ref={userRef}
              onChange={e => {
                setDetails({ ...details, confirmPassword: e.target.value })
                e.target.setCustomValidity('');
              }}
              onInvalid={e => e.target.setCustomValidity("Veuillez entrer un mot de passe")}
              value={details.confirmPassword}
              required
            />
          </div>
          <input className="input" name="caca" type={"submit"} value={"S'INSCRIRE"} />
          <div className="help-msg">
            <p className="p">Vous avre déjà un compte?</p>
            <Link data-testid="redirectLogin" to={'/login'}>Se connecter</Link>
          </div>
        </div>
      </form>
    </div>
  )
}

export default RegisterForm;