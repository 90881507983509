import React, { useEffect, useState } from "react";
import "./PageForm.scss";
import Navbar from '../../components/navbar/navbar.js';
import { useQuery, useMutation } from "@apollo/client";
import pp from '../../assets/logoVr.svg'
import ppLittle from '../../assets/logoVrLittle.svg'
import cross from '../../assets/cross.svg'
import { useParams } from "react-router-dom";
import arrow from '../../assets/arrow.svg'
import time from '../../assets/time.svg'
import coeur from '../../assets/coeur.svg'
import coeurVide from '../../assets/coeurVide.svg'
import vector from '../../assets/vector.svg'
import fond from '../../assets/fond.svg'
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from "../../components/LoadingIndicator/loadingIndicator";
import { USER_QUERY, DELETE_POST_MUTATION, USER_MUTATION, USER_MUTATION_LIKE, DELETE_COMMENT_MUTATION } from './graphqlMutations'; // Exemple de séparation des mutations

export default function PageForm(props) {
  const Params = useParams();
  const [comment, setComment] = useState('');
  const [isGood, setGood] = useState(false);
  const [heart, setHeart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const now = new Date();

  const verifyLiked = (data) => {
    if (data.findOnePost.likedId.length === 0) {
      setHeart(false);
    }
    for (let i = 0; i < data.findOnePost.likedId.length; i++) {
      if (data.findOnePost.likedId[i] === data.findOneUserByUsername.id) {
        setHeart(true);
        break;
      } else {
        setHeart(false);
      }
    }
  }

  useEffect(() => {
    if (comment !== '' && comment.length > 5 && comment.length < 255 && comment !== " ") {
      setGood(true);
    } else {
      setGood(false);
    }
  }, [comment])

  const handleDeleteClick = () => {
    if ((data?.findOnePost?.user?.id).toString() === localStorage.getItem("id")) {
      console.log("data ->", data?.findOnePost?.user?.id)
      deletePost();
    } else {
      console.log("data ->", data?.findOnePost?.user?.id)
      console.log("localStorage ->", localStorage.getItem("id"))
      alert("Vous n'êtes pas autorisé à supprimer ce post.");
    }
  };

  const [likePost, { loading: mutationLoadingLike, error: mutationErrorLike }] = useMutation(USER_MUTATION_LIKE, {
    variables: { data: { postId: Number(Params.token) } },
    refetchQueries: [
      { query: USER_QUERY, variables: { id: Number(Params.token), input: localStorage.getItem("user") ? localStorage.getItem("user") : "" } },
      'findOnePost'
    ],
    context: {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("auth-token")
      }
    },
    onCompleted: (data) => {
      //  window.location.reload();
    },
  });

  const [createComment, { loading: mutationLoading, error: mutationError }] = useMutation(USER_MUTATION, {
    variables: {
      input: { comment: "test", postId: Number(Params.token), userid: 1 }
    }, refetchQueries: [
      { query: USER_QUERY, variables: { id: Number(Params.token), input: localStorage.getItem("user") ? localStorage.getItem("user") : "" } },
      'findOnePost'
    ],
    onCompleted: (data) => {
      setComment('');
    },
    onError: (error) => {
      console.log(error)
    }
  });


  const { loading, error, data } = useQuery(USER_QUERY, {
    variables: { id: Number(Params.token), input: localStorage.getItem("user") ? localStorage.getItem("user") : "" },
    onCompleted: (data) => {
      console.log(data)
      verifyLiked(data);
    },
  });


  const [deletePost] = useMutation(DELETE_POST_MUTATION, {
    variables: { id: Number(Params.token) },
    onCompleted: () => {
      navigate(
        "/forum-categorie/" + pathPost(data.findOnePost.category)
      )
    },
    onError: (error) => {
      // Gérer les erreursx
    }
  });

  const [deleteComment] = useMutation(DELETE_COMMENT_MUTATION, {
    variables: { id: "" }, // Remplacez `commentId` par l'ID réel du commentaire
    onCompleted: () => {
      window.location.reload();
    },
    onError: (error) => {
      // Gérer les erreurs
    }
  });

  const handleDeleteCommentClick = (comment) => {
    const userId = localStorage.getItem("id");
    if ((comment.user.id).toString() === userId) {
      deleteComment({ variables: { id: comment.id } });
    } else {
      alert("Vous n'êtes pas autorisé à supprimer ce commentaire.");
    }
  };

  const createDIfferenceBetweenDate = (dates) => {
    const date = new Date(dates);
    let date1 = new Date(now);
    let diff = date - date1;
    const diffInDays = Math.abs(Math.floor(diff / (1000 * 60 * 60 * 24)));
    return diffInDays;
  }

  const createCommentSubmit = (e) => {
    e.preventDefault();
    if (data.findOneUserByUsername === null) {
      alert("Vous devez être connecté pour commenter")
      return;
    }
    if (isGood === true) {
      createComment({ variables: { input: { comment: comment, postId: Number(Params.token), userId: data.findOneUserByUsername.id } } });
    } else {
      alert("Votre commentaire doit contenir entre 5 et 255 caractères")
    }
  }

  const pathPost = (path) => {
    switch (path) {
      case "VR":
        return "vr";
      case "ANECDOTE":
        return "anecdote";
      case "DAYLILIFE":
        return "quotidien";
      case "QUESTIONS":
        return "maladie";
      default:
        return "VR";
    }
  }
  if (loading) return <LoadingIndicator />;
  if (error) return <p>Erreur : {error.message}</p>;

  return (
    <div className="page-form">
      <Navbar />
      <div className="position-in-form">
        <div className="round">
          <img className="arrow" alt={"arrow"} src={arrow} onClick={(e) => {
            navigate(
              "/forum-categorie/" + pathPost(data.findOnePost.category)
            )
          }} />
        </div>
        <p className="location">Forum -{">"} {
          data.findOnePost.category
        } -{">"} {
            data.findOnePost.title
          }</p>
      </div>
      <div className="subject-front">
        <div className="block-form">
          {
            data.findOnePost.user.id.toString() === localStorage.getItem("id") && (
              <div className="cross">
                <img className="cross-img" alt={"cross"} src={cross} onClick={handleDeleteClick} />
              </div>
            )
          }
          <div className="title-form">
            <img className="logo-title-form" alt={"logo"} src={data.findOnePost.user.url_picture ? data.findOnePost.user.url_picture : ppLittle} />
            <div className="title-sub">
              <p className="title">{data.findOnePost.title}</p>
              <p className="sub-title">{data.findOnePost.user.username}</p>
              <div className="date-block">
                <img className="time" alt={"time"} src={time} />
                <p className="date">Il y a {
                  createDIfferenceBetweenDate(data.findOnePost.created_at)
                } jours </p>
              </div>
            </div>
          </div>
          <div className="text-forum">
            {data.findOnePost.message}
          </div>
          <div className="row-forum">
            <p style={{ opacity: "0.6" }}>{
              data.findOnePost.likes
            }</p>
            <img className="coeur" alt={"coeur"} src={heart ? coeur : coeurVide} onClick={() => {
              data.findOneUserByUsername !== null ? likePost() : alert("Vous devez être connecté pour liker un post")
            }} />
          </div>
          <div className="row-forum">
            <img className="vector" alt={"vector"} src={vector} />
            <p className="answer">{
              data.findOnePost.comments.length
            } réponses</p>
          </div>
        </div>
      </div>
      <div className="all-answer">
        {
          data.findOnePost.comments.map((comment) => {
            return (
              <div key={
                comment.id
              } className="answer-block">
                {comment.user.id.toString() === localStorage.getItem("id") && (
                  <div className="cross">
                    <img className="cross-img" alt={"cross"} src={cross} onClick={() => handleDeleteCommentClick(comment)} />
                  </div>
                )}
                <div className="title">
                  <img className="logo-form" alt={"logo"} src={
                    comment.user.url_picture ? comment.user.url_picture : ppLittle
                  } />
                  <p className="name-answer">
                    {
                      comment.user.username
                    }
                  </p>
                  <div className="all-time">
                    <img className="time" alt={"time"} src={time} />
                    <p>Il y a {
                      createDIfferenceBetweenDate(comment.created_at)
                    } jours </p>
                  </div>
                </div>
                <p className="text-answer">
                  {
                    comment.comment
                  }
                </p>
              </div>
            )
          })}
      </div>
      <div className="answer-pos">
        <div className="answer-to-subject">
          <p className="react-subject">
            Reagir A ce sujet
          </p>
          <div className="logo-and-name">
            <img className="logo" alt={"logo"} src={
              data.findOneUserByUsername !== null ? data.findOneUserByUsername.url_picture ? data.findOneUserByUsername.url_picture : pp : pp
            } />
            <p className="name" >
              {
                data.findOneUserByUsername !== null ? data.findOneUserByUsername.username : "not connected"
              }
            </p>
          </div>
          <textarea className="text-area" cols={50} rows={4} type={"text"} placeholder="Ecrire un message.... " value={comment} onChange={(e) => setComment(e.target.value)} />
          <p style={{ opacity: "0.6000000238418579" }}>{comment.length}/255</p>
          <div className="button-send" onClick={(e) => createCommentSubmit(e)}>
            <p className="text" >Répondre</p>
          </div>
        </div>
      </div>
      <div className='footer'>
        <div className='footer-space'>
          <div className='block-footer'>
            <p className='title'>
              CONTACTS
            </p>
            <div className='text'>
              <p>06 61 74 16 78</p>
              <p>leukidemia@gmail.com </p>
            </div>
          </div>
          <div>
            <p className='title'>
              Au sujet de nous
            </p>
            <p className='text'>
              lien site landing page
            </p>
          </div>
          <div>
            <p className='title'>
              CONFIDENTIALITE
            </p>
            <p className='text'>
              conditions d’utilisation
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}