import React, { useEffect } from "react";
import "./EnterEmail.scss";
import "../ResetPassword/ResetPassword.scss";
import BackGround from '../../BackGround/BackGround';
import BasicInput from '../../../components/form/BasicInput/BasicInput';
import BasicButton from '../../../components/form/BasicButton/BasicButton';
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { get, set } from "react-hook-form";


export default function EnterEmail() {
    const [state, SetState] = React.useState(0);
    const [emails, setEmail] = React.useState("");

    const EMAIL_QUERY = gql`query sendEmail($input: String!) {
        sendEmail(email: $input)
      }`;
    const [loadGreeting, { called, loading, data }] = useLazyQuery(EMAIL_QUERY);

    return (
        <div>
            <div className='reset-password' >
                <p className='title-card'>
                    Leukidemia
                </p>
                <div className='reset-password-card'>
                    <div className='paragraphe'>
                        <p className='text title'>
                            Réinitialiser le mot de passe
                        </p>
                        <p className='text paragraphe-title'>
                        </p>
                    </div>
                    <BasicInput placeholder="Entrer votre email..." seter={setEmail} label="Email" />
                    <BasicButton text="Continuer" statusButton='selected' setState={SetState} state={emails} element={
                        loadGreeting}/>
                </div>
            </div>
            <BackGround />
        </div>
    );
}

