import { gql } from "@apollo/client";

export const USER_MUTATION = gql`mutation createComment($input: CreateCommentInput!) {
    createComment(createCommentInput: $input) {
      id
      }
}`;


export const USER_MUTATION_LIKE = gql`mutation  likePost($data: likePost!) {
    likePost(data: $data)
}`;

export const DELETE_COMMENT_MUTATION = gql`mutation removeComment($id: Int!) {
  removeComment(id: $id)
}`;

export const DELETE_POST_MUTATION = gql`mutation removePost($id: Int!) {
  removePost(id: $id)
}`;

export const USER_QUERY = gql`query  findOnePost($id: Int!, $input: String!) {
    findOnePost(id: $id ) {
    id
    title
    message
    category
    created_at
    likes
    likedId
    user {
      url_picture
      id
      username
      created_at
      role
    }
    comments {
      id
      created_at
      comment
      user {
        id
        username
        created_at
        url_picture
        role
    	}
    }
  },
  findOneUserByUsername(username: $input) {
    id,
    username,
    url_picture
  }
  }`;
