import React from 'react'
import RegisterForm from '../../components/form/RegisterForm/RegisterForm'
import "./Register.scss";
import Navbar from '../../components/navbar/navbar.js';

function Register() {
  return (
    <div className='register-page'>
      <Navbar />
      <RegisterForm />
    </div >
  )
}

export default Register