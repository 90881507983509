import React from "react";
import { useForm } from "react-hook-form";
import { Accordion, Container, Card, Button, Modal } from 'react-bootstrap';
import { useMemo } from "react";
import SideNav from "../../components/sidebar";

export default function Forum() {
  const { reset, register, formState: { errors }, handleSubmit } = useForm();
  const {
    reset: reset2,
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({});
  const [showModal, setShowModal] = React.useState(false);
  const [showAnswerModal, setShowAnswerModal] = React.useState(false);
  const [accordions, setAccordions] = React.useState(["Question 1", "Question 2", "Question 3"]);
  const [answers, setAnswers] = React.useState(['Answer1', 'Answer2']);

  const onSubmit = (data) => {reset();setAccordions([...accordions, data.question])};
  const onSubmitAnswer = (data) => {reset2();setAnswers([...answers, data.answer])}

  //reset les forms + reverif l'answer postée
  const content = useMemo(() => {


    return (<Accordion defaultActiveKey={0}>
        { accordions.map((question, idx) => {
        return <Accordion.Item eventKey={idx} key={idx}>
          <Accordion.Header>
            <div className="me-3 w-100 d-flex justify-content-between align-items-center">
              <span>{question}</span>
              <Button onClick={() => {setAccordions([
                ...accordions.slice(0, idx),
                ...accordions.slice(idx + 1, accordions.length)
              ]);}}>delete</Button>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            {answers.map((item, idx) => {
              return <Card className="mb-3" key={idx}>
              <Card.Header>From Unknown</Card.Header>
              <Card.Body>
                {item}
            </Card.Body>
            </Card>})}
          <div className="d-flex justify-content-center">
            <Button onClick={() => {setShowAnswerModal(true)}} size="lg" className="mt-2 w-75 ">Répondre</Button>
          </div>
          </Accordion.Body>
        </Accordion.Item>})}
      </Accordion>)
  }, [accordions, answers])

  return (
    <div className="d-flex d-row">
      <SideNav exclusiveExpand={true}
        rtl={true}
        expand="md"
        hide="md"></SideNav>
      <Container className='mt-5 main-container container-fluid'>
        <h1 className='mb-5'>Forum</h1>
        <div className="d-flex flex-row-reverse">
          <Button className="mb-3" onClick={() => {setShowModal(true)}}>Ask a Question</Button>
        </div>
        {content}

        <Modal
        className="rounded border border-2 border-primary"
        contentClassName="text-center"
        show={showModal}
        onHide={() => {setShowModal(false)}}
        backdrop="static"
        centered>
          <form id="question-form" onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header className="border-bottom-0" closeButton></Modal.Header>
          <Modal.Body className="d-flex flex-column align-items-center pb-0">
            <div className='mb-3'>
              <h3 className="mb-0">Poser une question</h3>
            </div>
            <div>
              <input type='text' name="question" {...register('question', {required:true})}/>
              {errors.question && errors.question.type === "required" && <span>This is required</span>}
            </div>
          </Modal.Body>
          <Modal.Footer
            className="border-top-0"
            style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit" onClick={()=>{setShowModal(false)}}>
              <div className="px-4">Envoyer</div>
            </Button>
          </Modal.Footer>
          </form>
        </Modal>

        <Modal
        className="rounded border border-2 border-primary"
        contentClassName="text-center"
        show={showAnswerModal}
        onHide={() => {setShowAnswerModal(false)}}
        backdrop="static"
        centered>
          <form id="answer-form" onSubmit={handleSubmit2(onSubmitAnswer)}>
          <Modal.Header className="border-bottom-0" closeButton></Modal.Header>
          <Modal.Body className="d-flex flex-column align-items-center pb-0">
            <div className='mb-3'>
              <h3 className="mb-0">Répondre</h3>
            </div>
            <div>
              <input type='text' name="answer" {...register2('answer', {required:true})}/>
              {errors2.answer && errors2.answer.type === "required" && <span>This is required</span>}
            </div>
          </Modal.Body>
          <Modal.Footer
            className="border-top-0"
            style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit" onClick={()=>{setShowAnswerModal(false)}}>
              <div className="px-4">Envoyer</div>
            </Button>
          </Modal.Footer>
          </form>
        </Modal>
      </Container>
    </div>
  );
}