import React from "react";
import './footer.scss';

export default function Footer() {
    return (
    <div className='footer'>
        <div className='footer-space'>
            <div className='block-footer'>
                <p className='title'>
                    CONTACTS
                </p>
                <div className='text'>
                    <p>06 61 74 16 78</p>
                    <p>leukidemia@gmail.com </p>
                </div>
            </div>
            <div>
                <p className='title'>
                    Au sujet de nous
                </p>
                <p className='text'>
                    lien site landing page
                </p>
            </div>
            <div>
                <p className='title'>
                    CONFIDENTIALITE
                </p>
                <p className='text'>
                    conditions d’utilisation
                </p>
            </div>
        </div>
    </div>
    )
}