import React from "react";
import Navbar from "../../components/navbar/navbar";
import BoxGuide from "../../components/BoxGuide/BoxGuide";
import plop from "../../assets/plop.png";
import tmp from "../../assets/tmp.png";
import loko from "../../assets/loko.png";

import './VrGuide.scss';
import Footer from "../../components/Footer/Footer";

export default function VrGuide() {
    return (
        <>
            <Navbar />
            <div className="guide-vr">
                <div className="balise-guide">
                    <h1 className="title-guide">
                        Casque Oculus Quest: Notions de base
                    </h1>
                    <h5> Découvrez les principes de base en matière d'utilisation</h5>
                    <BoxGuide
                        title="1. Allumer le casque et vérifier sa batterie"
                        paragraph="Pour allumer l'Oculus Quest 2, maintenez enfoncé le bouton d'alimentation situé sur le côté droit du casque jusqu'à ce que le voyant s'allume. Pour le recharger, connectez le câble USB-C inclus à un adaptateur secteur et branchez-le au port USB-C du casque. Le voyant devient orange lorsqu'il charge et vert une fois complètement chargé. Pour vérifier le niveau de batterie, mettez le casque et regardez l'indicateur de batterie dans le menu rapide ou dans les paramètres du casque.                        "
                    />
                    <BoxGuide
                        title="2. Comment redemarrer mon casque en cas de problème"
                        paragraph="Si vous rencontrez un problème avec votre casque Oculus for Business, essayez de le redémarrer. Pour redémarrer l’appareil :
                        Maintenez le bouton Marche/Arrêt enfoncé pour afficher les options d’extinction.
                        Sélectionnez Redémarrer avec votre manette pour redémarrer l’appareil."
                    />
                    <BoxGuide
                        title="3. Comment configurer le gradiant"
                        paragraph={
                            <>
                                <p>Pour configurer le Guardian sur Oculus Quest, suivez ces étapes :</p>
                                <p>1. Allumez votre Oculus Quest et mettez votre casque.</p>
                                <p>2. Appuyez sur le bouton Oculus pour ouvrir le menu principal.</p>
                                <p>3. Sélectionnez l'icône "Paramètres" (engrenage) en bas de la barre de menu.</p>
                                <p>4. Dans les paramètres, allez dans la section "Guardian".</p>
                                <p>5. Choisissez "Configurer le Guardian" pour commencer le processus de configuration.</p>
                                <p>6. Suivez les instructions à l'écran pour tracer les limites de votre espace de jeu.</p>
                                <p>7. Ajustez les paramètres de sensibilité selon vos préférences.</p>
                                <p>8. Une fois terminé, le système Guardian est activé pour assurer votre sécurité pendant que vous jouez.</p>

                            </>
                        }
                    />
                </div>
                <div className="balise-guide">
                    <h1 className="title-guide">
                        Manettes du Oculus Quest
                    </h1>
                    <BoxGuide
                        title="1. La configuration des manettes"
                        paragraph="Pour configurer les manettes de l'Oculus Quest 2, commencez par insérer les piles AA fournies dans chaque manette, en veillant à respecter la polarité indiquée. Allumez ensuite le casque Oculus Quest 2 en maintenant enfoncé le bouton d'alimentation. Une fois le casque allumé, mettez-le sur votre tête et suivez les instructions à l'écran pour la configuration initiale. Les manettes devraient être automatiquement détectées par le casque. Si elles ne le sont pas, maintenez enfoncés les boutons Oculus (bouton rond avec le logo Oculus) sur chaque manette jusqu'à ce qu'elles s'allument et soient reconnues. Assurez-vous que les manettes sont visibles par les capteurs du casque pendant le processus de jumelage. Enfin, suivez les instructions à l'écran pour terminer la calibration des manettes, qui peut inclure la délimitation de votre espace de jeu.                        "
                    />
                    <BoxGuide
                        title="2. Les touches sur les manettes"
                        paragraph={
                            <div>
                                <p>A. Le Joystick Gauche : Permet de se déplacer dans l’environnement.</p>
                                <p>B. Le bouton X : Permet d’interagir avec l’environnement.</p>
                                <p>C. Le bouton Y : Permet d’interagir avec l’environnement.</p>
                                <p>D. Le grip gauche : Permet d’interagir avec l’environnement.</p>
                                <p>E. Le curseur Gauche : Permet d’interagir avec l’environnement.</p>
                                <p>F. Le bouton Menu gauche : Permet d’ouvrir le menu du casque.</p>
                                <p>G. Le bouton B : Permet d’interagir avec l’environnement.</p>
                                <p>H. Le bouton A : Permet d’interagir avec l’environnement.</p>
                                <p>I. Le grip droit : Permet d’interagir avec l’environnement.</p>
                                <p>J. Le Curseur droit : Permet d’interagir avec l’environnement.</p>
                                <p>K. Le bouton menu droit : Permet d’ouvrir le menu du casque.</p>
                            </div>
                        }
                        image={plop}
                    />
                </div>
                <div className="balise-guide">
                    <h1 className="title-guide">
                        Début du jeu
                    </h1>
                    <BoxGuide
                        title="1. Comment se diriger dans le jeu"
                        paragraph="Afin de bouger dans le monde virtuel, on utilise le bouton derrière la manette. On appelle les boutons arrière les boutons de grip. Une zone apparait et elle peut être bougée par le viseur de la manette. Cette zone est l’endroit où l’on va pouvoir se téléporter. La couleur de la zone représente s'il est possible de s’y téléporter. La couleur rouge signale que l’on ne peut pas s’y téléporter alors que la couleur verte signale que l’on peut."
                    />
                    <BoxGuide
                        title="2. Comment intéragir avec les PNJ"
                        paragraph="Pour intéragir avec les PNJ (Personnages non joueurs), vous devez cliquer sur le bouton Vert avec votre manette. Celui ci se mettra surement a parler, un texte s'affichera au dessus de lui."
                        image={tmp}
                    />
                    <BoxGuide
                        title="3. Le choix des chapitres"
                        paragraph={
                            <>
                                <p>Lorsqu’on lance une partie, on atterrit dans une zone avec plein de porte que l’on appelle l’espace de “Sélection de chapitre”. Cela permet de continuer l'aventure ou de refaire les niveaux déjà exécutés. Lorsqu’on finit un chapitre, il débloquera instantanément le prochain chapitre. Pour choisir un chapitre, il faut d’abord s’assurer qu’il est débloqué. Ensuite, pointez votre manette vers la porte débloquée et appuyez sur le bouton A.</p>
                                <p> Le jeu se décompose en 3 chapitres. Dans le premier nous allons voir comment fonctionne le corps humain. Plus particulièrement le fonctionnement du cœur et de la circulation sanguine. Ce chapitre sert d’introduction et de tutoriel pour que l’utilisateur prenne ses repères. Le deuxième chapitre s’articule autour de la leucémie et des ses effets sur le corps humain. Le troisième et dernier chapitre est dédié au traitement de la leucémie et à l’impact du traitement sur le corps. </p>
                            </>
                        }
                        image={loko}
                    />
                    <BoxGuide
                        title="4. Savoir si c'est cliquable"
                        paragraph="Si le bouton est cliquable, il devient vert. Sinon il reste rouge.Le gradiant en face devient alors rouge si ce n'est pas clickable ou alors si on ne peut pas y aller"
                    />
                    <BoxGuide
                        title="5. Comment on peut modifier le son"
                        paragraph={
                            <>
                                <p>1. Dans le jeu, vous rencontrerez des moments où le son est présent et d'autres où il est absent.</p>
                                <p>2. Cette variabilité sonore enrichit l'expérience de jeu, offrant une immersion dynamique.</p>
                                <p>3. Si vous souhaitez ajuster ces paramètres sonores, vous avez la possibilité de le faire.</p>
                                <p>4. Rendez-vous dans le menu des options pour configurer le son selon vos préférences.</p>
                                <p>5. Que ce soit pour augmenter, diminuer ou désactiver le son, les options sont facilement accessibles.</p>
                            </>
                        }
                    />
                </div>
            </div>
            <Footer />
        </>
    );
}