import React, { useEffect } from 'react';
import BasicInput from '../../../components/form/BasicInput/BasicInput';
import BasicButton from '../../../components/form/BasicButton/BasicButton';

import "./ResetPassword.scss";
import BackGround from '../../BackGround/BackGround';
import { useParams } from 'react-router-dom';
import { gql, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom"

export default function ResetPassword() {
    const [state, SetState] = React.useState(0);
    const [password, SetPassword] = React.useState("");
    const [errorMsg, setErrorMsg] = React.useState("");
    const [confirmPassword, SetConfirmPassword] = React.useState("");
    const [validePAssword, SetValidePAssword] = React.useState("wrong")
    const [valideConfirmPAssword, SetValideConfirmPAssword] = React.useState("wrong")

    const textState = " Entrer deux fois le nouveau password.";
    const token = useParams();

    const MODIFY_PASSWORD = gql`mutation modifyPassword($input: String!, $input2: String!) {
        modifyPassword (token: $input, password: $input2){password}
      }`;

    const [ModifyPassword, { data, loading, error }] = useMutation(MODIFY_PASSWORD);


    useEffect(() => {
        let strongRegex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$");
        if (!strongRegex.test(password)) {
            SetValideConfirmPAssword("wrong");
        } else {
            SetValideConfirmPAssword("none");
        }
        if (password === confirmPassword && password !== null) {
            SetValidePAssword("none");
        } else {
            SetValidePAssword("wrong");
        }
    }, [password, confirmPassword]);
    const navigate = useNavigate()

    if (loading) {
        navigate("/login");
    }

    return (
        <div>
            <div className='reset-password'>
                <p className='title-card'>
                    Leukidemia
                </p>
                <div className='reset-password-card'>
                    <div className='paragraphe'>
                        <p className='text title'>
                            Réinitialiser le mot de passe
                        </p>
                        <p className='text paragraphe-title'>
                            {textState}
                        </p>
                    </div>
                    <div className='password-type'>
                        <BasicInput type="password" placeholder="Entrer votre mot de passe" status={valideConfirmPAssword} label="Mot de passe" variable={password} seter={SetPassword} />
                        <BasicInput type="password" status={validePAssword} placeholder="Entrer votre mot de passe" state="unselected" label="Mot de passe" variable={confirmPassword} seter={SetConfirmPassword} />
                        <button className={`basic-button status--selected}`} onClick={(e) => {
                            ModifyPassword({ variables: { input: JSON.stringify(token), input2: password } });
                        }}>
                            <p className="text-button">
                                Valider
                            </p>
                        </button>
                    </div>
                </div>
            </div>
            <BackGround />
        </div>
    )
}

/*<input type="text" name="new-password" placeholder='new-password'/>
                <input type="text" name="same-password" placeholder='same-password' />
                <input type= "text" name="id-compte" placeholder='email or mobile'/>*/