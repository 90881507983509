import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/style.scss';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { BACK_URL } from './constants';
import {store } from "./store/store";
import { Provider } from 'react-redux'
const { createUploadLink } = require('apollo-upload-client')

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: createUploadLink({
    uri: `${BACK_URL}/graphql`,
  }),
  defaultOptions: {
    query: {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network'
    },
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Provider store={store}>
        <ApolloProvider  client={client}>
          <App />
        </ApolloProvider>
        </Provider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
