import React from 'react';
import "./BasicInput.scss";


export default function BasicInput(props) {

    const isLabel = props.label !== null && <label className="name-input">{props.label}</label>


    return (
        <div className={`input-container color--${props.status}`}>
            {isLabel}
            <input type={props.type} style={{ width: props.widthInput !== null ? props.widthInput : "100%" }} className={`basic-input shadow--${props.state}`} placeholder={props.placeholder} onChange={(e) => {
                props.seter(e.target.value);
                console.log(e.target.value);
            }}>
            </input>
        </div>
    );
}